.flex-1 {
    flex: 1;
}

.primary-wrapper {
    position: relative;
    height: 90vh;
    .loader-wrapper {
        div {
            position: absolute;
            left: 48%;
            top: 50%;
            z-index: 3;
        }
    }
}