.landing-wrapper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: table;
    .download-wrapper {
        display: table-row;
        .left-card {
            display: table-cell;
            background-color: #FFF6F6;
            width: 50%;
            padding-top: 75px;
        }
        .divider {
            width: 20px;
        }
        .right-card {
            display: table-cell;
            background-color: #F6F6F6;
            width: 50%;
            padding-top: 75px;
        }
        .download {
            text-transform: uppercase;
            color: rgba(0, 0, 0, .5);
        }
        .device {
            font-size: xx-large;
            font-weight: 400;
        }
        .device-image {
            img {
                height: 200px;
            }
        }
        .app-store-logo {
            width: 150px;
            cursor: pointer;
        }
        .windows-download {
            p {
                color: rgba(0, 0, 0, .5);
            }
            a {
                cursor: pointer;
                color: #39b3ed;
            }
        }
        .mac-button {
            cursor: pointer;
            a {
                border-radius: 40px;
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: .071em;
                line-height: 19px;
                padding: 13px 18px;
                text-transform: uppercase;
                white-space: nowrap;
                background-color: #99383b;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}