@media(max-width: 576px) {
    .dashboard-wrapper {
        .card {
            margin-bottom: 20px;
        }
    }
    .students-wrapper {
        .student-table-wrapper {
            justify-content: center;
        }
    }
    .navbar-wrapper {
        .navbar-list {
            display: none !important;
        }
        .admin-login {
            display: none !important;
        }
    }
}

@media(min-width: 577px) {
    .sidenav-wrapper {
        .burger-icon {
            display: none;
        }
    }
}

// Landing page
@media(min-width: 1440px) {
    .landing-wrapper {
        width: 1440px;
    }
}

@media(max-width: 1399px) {
    .landing-wrapper {
        width: 100%;
    }
}

@media(max-width: 767px) {
    .landing-wrapper {
        .download-wrapper {
            display: block !important;
            .left-card, .right-card {
                width: 100% !important;
                display: block !important;
            }
            .right-card {
                margin-top: 2rem;
            }
        }
    }
}