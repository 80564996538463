.navbar {
    background: #99383b url("../../assets/top-bar-bg.png") center center repeat-x;
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    overflow: hidden;
    background-size: cover;
}
    
.link {
    color: #fff;
    line-height: 45px;
    margin-right: 8px;
    margin-left: 8px;
    text-decoration: none;
    font-size: 13px;
    text-transform: uppercase;
}

.link:hover {
    color: white;
    text-decoration: none;
}

.linkActive {
    text-decoration: none;
    font-weight: bolder;
}

a {
    padding: 0 0.5rem;
}

.logo {
    height: 50px;
    cursor: pointer;
}

