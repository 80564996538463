@import '../../styles/main';

.card {
    background-color: $cardBackgroundColor;
    border: 0 solid transparent;
    border: 1px solid #dddddd69;
    border-radius: 0.25rem;
    box-shadow:$cardBoxShadow;
    color: $cardTextColor;
    font-size: $body-2;
    font-weight: $fontRegular;
    line-height: 1.25rem;
    overflow: initial;
    display: flex;
    flex-direction: column;
    height: $cardMinHeight;
  
    .card-body {
      flex: 1;
      -ms-flex: 1 1 auto;
      display: block;
      padding: 1rem 1.25rem;
      position: relative;
    }

    .card-title {
      font-size: $header-5;
    }

    .card-value {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: $fontSizeXL;
    }
}