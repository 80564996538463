// Alignments
.align-left {
	align-items: flex-start;
}

.align-right {
	align-items: flex-end;
}

.align-center {
	align-items: center !important;
}

.justify-left {
	justify-content: flex-start !important;
}

.justify-right {
	justify-content: flex-end !important;
}

.justify-center {
	justify-content: center !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.vertical-top {
	vertical-align: top !important;
}

.vertical-middle {
	vertical-align: middle !important;
}

.vertical-bottom {
	vertical-align: bottom !important;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}


// Positions

.p-relative {
	position: relative !important;
}

.p-absolute {
	position: absolute !important;
}

.p-static {
	position: static !important;
}


.cursor-pointer {
	cursor: pointer;
}